
import { numberFormat } from '@/utils/text';
import timeUtil from '@/utils/time';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import EmailReportModal from '@/views/v2/views/pages/ViewTenantReview/EmailReport.vue';
import toasts from '@/utils/toasts';
import PDFGenerator from '@/views/v2/views/components/PDFGenerator.vue';
import LedgerReport from '@/views/v2/views/pages/Report/Ledger.vue';
import image from '@/utils/image';
import NoReviewSubmitted from '@/views/v2/views/pages/ViewTenantReview/NoReviewSubmitted.vue';
import { User } from '@/models/UserModel';
import { money } from '@/utils/currency';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';

const badgeColor = {
  yes: 'badge-yes',
  no: 'badge-no',
  'n/a': 'badge-not-applicable',
};

export default defineComponent({
  name: 'rental-review-details-section',
  props: {
    tenantReviews: {
      type: Array,
      default: () => undefined,
    },
    currentTenantReview: {
      type: Object,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    reviewDetailLoading: {
      type: Boolean,
      required: false,
    },
    averageRating: {
      type: String,
      default: '0.00',
    },
    tenantInfo: {
      type: Object,
      default: () => {
        return {} as unknown as User;
      }
    }
  },
  components: {
    EmailReportModal,
    PDFGenerator,
    LedgerReport,
    NoReviewSubmitted
  },
  data() {
    return {
      downloading: false,
      sendReport: false,
      sendingReport: false,
      generatingReport: false,
      pdfFile: '',
      googleMapImage: '',
    };
  },
  computed: {
    ...mapGetters({
      actionError: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      tenant: AuthGetters.GET_AUTH_USER,
    }),
    hasTenantReviews() {
      return typeof this.tenantReviews !== 'undefined'
        ? this.tenantReviews.length
        : false;
    },
    address() {
      return `${(this.currentTenantReview as any)?.address_line}, ${
        (this.currentTenantReview as any)?.city
      }, ${(this.currentTenantReview as any)?.post_code}, ${
        (this.currentTenantReview as any)?.state
      }`;
    },
    tenantRole() {
      // return this.currentTenant.roles[0];
      return 'Tenant';
    },
    googleStaticMapImageSrc() {
      const src = (this.currentTenantReview as any)?.map_static_src;
      return src && src != '' ? src : require('@/assets/images/map.png');
    },
    notReviewed() {
      // return (
      //   this.currentTenantReview?.review_answers?.filter((item) => {
      //     return item.answer == null;
      //   }).length > 0
      // );
      return this.currentTenantReview?.is_not_reviewed === 'yes'
    },
    isMoveDateGreaterThanCreatedDate() {
      const move_out_date = new Date(this.currentTenantReview?.move_out_date);
      const created_date = new Date(this.currentTenantReview?.date_created);

      return move_out_date > created_date;
    },
    rentPerWeek(){
      return money.format(this.currentTenantReview?.rent_per_week);
    }
  },
  methods: {
    ...mapActions({
      sendToEmails: Actions.SEND_TENANT_REVIEW_TO_EMAILS,
    }),
    getColor(answer = '' as string) {
      return ((typeof answer !== 'undefined' && answer !== null && answer !== '') || (Array.isArray(answer) && answer.length))
        ? badgeColor[answer.toLowerCase()]
        : '';
    },
    dateFormat(date) {
      return timeUtil.dateFormat(date);
    },
    numberWithCommas(x: any) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatAverageRating(rating) {
      return numberFormat(rating);
    },
    async download(type) {
      if (type == 'ledger') {
        this.downloadLedger();
      } else {
        this.generatePDF();
      }
    },
    async generatePDF() {
      await (this.$refs.pdfReport as any).generatePDf();
    },
    async downloadLedger() {
      if (this.downloading) return;
      this.downloading = true;

      // for indicator purposes only
      setTimeout(() => {
        this.downloading = false;
      }, 2000);

      const fileURL = this.currentTenantReview?.tenant_legder_src;
      const fileLink = document.createElement('a');

      fileLink.download = `${this.currentTenantReview?.reference_no}.pdf`;
      fileLink.href = fileURL;
      fileLink.target = '_blank';

      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },

    async handleSendReport() {
      await (this.$refs.senfPdfReport as any).generatePDf();
      this.sendReport = true;
    },

    async handleBeforeDownload(pdf) {
      if (this.pdfFile !== pdf) {
        this.pdfFile = pdf;
      }
    },

    async emailReport(emails) {
      const payload = {
        refNo: this.currentTenantReview?.reference_no,
        emails: Object.values(emails),
        report: this.pdfFile,
      };

      this.sendingReport = true;
      await this.sendToEmails(payload)
        .then(() => {
          toasts.success('Tenant Review Successfully Sent');
          (this.$refs.emailReport as any).clear();
        })
        .catch(() => {
          const { errors, message } = this.actionError;
          toasts.error(errors, message);
        })
        .finally(() => {
          this.sendingReport = false;
          this.sendReport = false;
        });
    },
  },
});
