
import { defineComponent } from 'vue';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { mapActions, mapGetters } from 'vuex';
import { Getters, Actions } from '@/store/enums/TenantReviewEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { TenantReview } from '@/models/TenantReviewModel';
import { acronym, numberFormat } from '@/utils/text';
import Report from '@/views/v2/views/pages/ViewTenantReview/Report.vue';
import AgentDetails from '@/views/v2/views/pages/ViewTenantReview/AgentDetails.vue';
import BusinessLogo from '@/components/logo/BusinessLogo.vue';
import useBreakpoints from 'vue-next-breakpoints';
import Comment from '@/views/v2/views/pages/ViewTenantReview/Comment.vue';
import toasts from '@/utils/toasts';

const initialValues = {
  id: '',
  tenant_id: '',
  reference_no: '',
} as {} as unknown as TenantReview;

export default defineComponent({
  name: 'view-tenant',
  props: {
    customClass: {
      type: String,
      default: 'mx-auto',
    },
  },
  data: () => ({
    currentTenantReview: initialValues,
    currentRefNo: '' as string,
    routeRefNo: '' as string | string[],
    tenantId: '' as string | string[],
    loading: false,
    reviewDetailLoading: false,
    averageRating: '0',
    tab: 'report',
    breakpoints: {},
    submittingComment: false,
    loadingComments: false,
  }),
  async mounted() {
    this.loading = true;
    this.reviewDetailLoading = true;
    setCurrentPageTitle('Get Tenant Reference');

    this.setTenantId();

    await this.loadTenantReviews();

    this.setCurrentTenantReview();

    this.computeAverageRating();

    this.breakpoints = useBreakpoints({
      mobile: 800, // max-width: 800px
      desktop: [801], // min-width: 601px
    });

    this.fetchTenantComments();
  },
  components: {
    Report,
    AgentDetails,
    BusinessLogo,
    Comment,
  },
  computed: {
    ...mapGetters({
      tenantReview: Getters.GET_TENANT_REVIEW,
      tenant: AuthGetters.GET_AUTH_USER,
      tenantReviews: Getters.GET_ALL_TENANT_REVIEWS,
      comments: Getters.GET_COMMENTS,
      actionErrors: Getters.GET_TENANT_REVIEW_ACTION_ERROR,
      authUser: AuthGetters.GET_AUTH_USER,
    }),

    tenantRecord() {
      return this.tenant;
    },
    currentAddress() {
      return `${(this.currentTenantReview as any)?.address_line}, ${
        (this.currentTenantReview as any)?.city
      }, ${(this.currentTenantReview as any)?.post_code}, ${
        (this.currentTenantReview as any)?.state
      }`;
    },
    headerColor() {
      if (!(this.currentTenantReview as any)?.user?.business_color) {
        return '#4070ea';
      }

      return (
        (this.currentTenantReview as any)?.user?.business_color ?? '#4070ea'
      );
    },
    hasLogo() {
      return (
        (this.currentTenantReview as any)?.user?.business_logo &&
        (this.currentTenantReview as any)?.user?.business_logo.trim() != ''
      );
    },
    businessLogoSrc() {
      return (this.currentTenantReview as any)?.user?.business_logo_src;
    },
    agentAvatarSrc() {
      return (this.currentTenantReview as any)?.user?.avatar_src;
    },
    agentAcronym() {
      const firstName = (this.currentTenantReview as any)?.user?.first_name;
      const lastName = (this.currentTenantReview as any)?.user?.last_name;

      if (firstName === '' && lastName === '')
        return acronym((this.currentTenantReview as any)?.user?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    businessName() {
      return (this.currentTenantReview as any)?.user?.business_name;
    },
    businessBackgroundColor() {
      return (this.currentTenantReview as any)?.user?.business_color;
    },
    businessTextColor() {
      return (this.currentTenantReview as any)?.user?.business_text_color;
    },
    isMobile() {
      return (this.breakpoints as any)?.mobile?.matches;
    },
    isDesktop() {
      return (this.breakpoints as any)?.desktop?.matches;
    },
    currentCoordinates(){
      return { 
        lat: parseFloat((this.currentTenantReview as any).latitude), 
        lng: parseFloat((this.currentTenantReview as any).longitude)
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.setCurrentTenantReview();
      },
      deep: true,
    },
    currentTenantReview: {
      handler(value: any) {
        if (value) {
          this.fetchTenantComments();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      fetchTenantReviews: Actions.FETCH_ALL_TENANT_REVIEWS,
      submitComment: Actions.SUBMIT_COMMENT,
      fetchComments: Actions.FETCH_COMMENTS,
    }),

    setTenantId() {
      this.tenantId = this.tenant.id;
    },

    setCurrentTenantReview() {
      if (
        typeof this.currentRefNo !== 'undefined' &&
        this.currentRefNo === ''
      ) {
        this.currentRefNo = this.tenantReviews[0].reference_no;
      }

      if (this.$route.params.ref) {
        this.currentRefNo = this.$route.params.ref as any;
      }

      // fetch tenant reviews by refno
      this.currentTenantReview = this.tenantReviews.filter((tenantReview) => {
        if (tenantReview.reference_no == this.currentRefNo) {
          return tenantReview;
        }
      })[0];

      this.loading = false;
      this.reviewDetailLoading = false;
    },

    async loadTenantReviews() {
      // fetch tenant reviews by tenant id
      await this.fetchTenantReviews(this.tenantId);

      this.loading = false;
      this.reviewDetailLoading = false;
    },

    handleSelectRentalHistory(values) {
      this.loading = true;
      this.reviewDetailLoading = true;

      setTimeout(() => {
        this.currentRefNo = values;
        this.setCurrentTenantReview();
      }, 500);
    },
    computeAverageRating() {
      if (
        typeof this.tenantReviews !== 'undefined' &&
        this.tenantReviews.length > 0
      ) {
        let sumRating = 0;
        this.tenantReviews.map((review: TenantReview) => {
          sumRating = sumRating + parseFloat(review.average_rating);
        }, 0);

        const average = `${sumRating / this.tenantReviews.length}`;
        this.averageRating = numberFormat(average);
      }
    },
    back() {
      this.$router.push({ name: 'tenant-profile-view' });
    },

    async submitTenantComment(data: any) {
      this.submittingComment = true;
      await this.submitComment({
        ...data,
        ...{ parent_id: null, ref_id: this.currentRefNo },
      })
        .then(() => {
          this.fetchTenantComments();
        })
        .catch(() => {
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        })
        .finally(() => (this.submittingComment = false));
    },
    async fetchTenantComments() {
      if (this.currentRefNo) {
        this.loadingComments = true;
        await this.fetchComments(this.currentRefNo).finally(() => {
          this.loadingComments = false;
        });
      }
    },
  },
});
