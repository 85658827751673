
import { defineComponent } from 'vue';

import Avatar from '@/components/avatars/Avatar.vue';

import { Comment } from '@/models/CommentModel';
import { acronym } from '@/utils/text';
import { User } from '@/models/UserModel';

export default defineComponent({
  components: { Avatar },
  props: {
    loadingList: { default: false },
    submitting: { default: false },
    comments: {
      type: Object,
      default: () => {
        return {} as unknown as Comment;
      },
    },
    userId: { default: null },
  },
  data() {
    return {
      form: {
        comment: '',
      },
    };
  },
  emits: ['submit'],
  methods: {
    formatAcronym(user: User) {
      const firstName = user?.first_name;
      const lastName = user?.last_name;

      if (firstName === '' && lastName === '') return acronym(user?.email);

      return acronym(`${firstName} ${lastName}`);
    },
    submit() {
      this.$emit('submit', this.form);
      this.$nextTick(() => (this.form.comment = ''));
    },
  },
});
