
import { defineComponent } from 'vue';
import Modal from '@/views/v2/views/components/Modal.vue';
import InputTag from '@/views/v2/views/components/InputTag.vue';
import { email as emailRegex } from '@/utils/regex';

export default defineComponent({
  components: { InputTag, Modal },
  props: {
    modelValue: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },
  emits: ['send', 'update:modelValue'],
  data() {
    return {
      emails: [],
    };
  },
  computed: {
    emailValidRegex() {
      return emailRegex;
    },
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    submit() {
      if (!this.emails.length) {
        return;
      }

      this.$emit('send', this.emails);
    },
    clear() {
      this.emails = [];
    },
  },
});
